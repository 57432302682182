import { AccountBadge, AccountDetails, AccountWrapper, BulletHeadline, BulletImage, BulletSubtext, BulletText, Contact, Container, Headline, MobileViewAllButton, StyledBullet, StyledBulletContainer, StyledDivider } from "./TwoCol_AccountBreakdownSection.styles"
import React, { useContext, useState } from 'react';

import { AnalyticsContext } from "../../../../context"
import Button from '../../../global/Button';
import { Link } from "gatsby"
import SectionWrapper from '../../../sections/SectionWrapper';

const leftColBullets = [
  {
    headline: "COST",
    bullets: [
      {
        text: "Free to sign up and use",
        subtext: "(Only pay for the amount spent.)"
      },
    ]
  },
  {
    headline: "Ordering Type",
    bullets: [
      {
        text: "Individual meal perks"
      },
      {
        text: "Scheduled team orders",
        subtext:"(No diner or order minimums. Admins set budgets and send out restaurant choices.)"
      },
      {
        text: "Group orders",
        subtext:"(Anyone on your team can start a shared cart and split meal costs with colleagues. Plus there's no minimums for number of diners or orders.)"
      },
      {
        text: "Catering",
      }
    ]
  },
  {
    headline: "Payment",
    bullets: [
      {
        text: "Invoice",
        subtext:"(Order now, pay later via check/ACH, wire, credit card)"
      },
      {
        text: "Credit card",
        subtext:"(Charged when orders are placed)",
        inactive: true,
        textgrey: true

      }
    ]
  },
  {
    headline: "ACCOUNT SUPPORT",
    bullets: [
      {
        text: "Corporate care",
      },
      {
        text: "Dedicated client success team",
      },
      {
        text: "Dedicated day-of staffing for every order (additional fees apply)",
      }
    ]
  },
  {
    headline: "SPENDING",
    bullets: [
      {
        text: "Budget customization",
      }
    ]
  },
  {
    headline: "REPORTING",
    bullets: [
      {
        text: "Detailed receipts",
      },
      {
        text: "Expense codes",
      },
      {
        text: "Cost comments",
      }
    ]
  },
  {
    headline: "SETUP METHOD",
    bullets: [
      {
        text: "Self-service",
        inactive: true,
        textgrey: true
      },
      {
        text: "Sales walkthrough",
      }
    ]
  }
]

const rightColBullets = [
  {
    headline: "COST",
    bullets: [
      {
        text: "Free to sign up and use",
        subtext: "(Only pay for the amount spent.)"
      },
    ]
  },
  {
    headline: "Ordering Type",
    bullets: [
      {
        text: "Individual meal perks",
      },
      {
        text: "Scheduled team orders",
        subtext:"(No diner or order minimums. Admins set budgets and send out restaurant choices.)",
        inactive: true,
        textgrey: true
      },
      {
        text: "Group orders",
        subtext:"(Anyone on your team can start a shared cart and split meal costs with colleagues. Plus there's no minimums for number of diners or orders.)"
      },
      {
        text: "Catering",
        inactive: true,
        textgrey: true
      }
    ]
  },
  {
    headline: "Payment",
    bullets: [
      {
        text: "Invoice",
        subtext:"(Order now, pay later via check/ACH, wire, credit card)",
        inactive: true,
        textgrey: true
      },
      {
        text: "Credit card",
        subtext:"(Charged when orders are placed)",
      }
    ]
  },
  {
    headline: "ACCOUNT SUPPORT",
    bullets: [
      {
        text: "Corporate care",
      },
      {
        text: "Dedicated client success team",
        inactive: true,
        textgrey: true
      },
      {
        text: "Dedicated day-of staffing for every order (additional fees apply)",
        inactive: true,
        textgrey: true
      }
    ]
  },
  {
    headline: "SPENDING",
    bullets: [
      {
        text: "Budget customization",
      }
    ]
  },
  {
    headline: "REPORTING",
    bullets: [
      {
        text: "Detailed receipts",
      },
      {
        text: "Expense codes",
        inactive: true,
        textgrey: true
      },
      {
        text: "Cost comments",
        inactive: true,
        textgrey: true
      }
    ]
  },
  {
    headline: "SETUP METHOD",
    bullets: [
      {
        text: "Self-service"
      },
      {
        text: "Sales walkthrough",
        inactive: true,
        textgrey: true
      }
    ]
  }
]

const TwoCol_AccountBreakdownSection = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)

  const [ panelsExpanded, setPanelsExpanded ] = useState([false, false]);
  //                                                        ^      ^
  //                                                      left   right
  //                                                      panel  panel

  const expandPanel = (panelIndex) => {
    // Copy state to temporary variable
    let temp = [...panelsExpanded];

    // Change the bool at panelIndex to true
    temp[panelIndex] = true;

    // Push the new array to state
    setPanelsExpanded(temp);
  }

  return(
  <SectionWrapper sectionPadding="0">
    <Container className="container">
      <AccountWrapper>
        <AccountBadge src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1649790086/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Signup%20Page/RecurringBenefitsMealIcon.png"   alt="Benefits Meal Icon"/>
        <AccountDetails isExpanded={panelsExpanded[0]}>
          <Headline>Enterprise Account</Headline>
          <p>A dedicated team is ready to help build your custom meal perk solution and support every step of the way! Perfect for large groups and daily or weekly ordering.</p>
          <Button
            to="/contact-us-enterprise-account/"
            onClick={() => handleTealiumEvent({ eventcategory: "get started page interaction", eventaction: "Get a demo call_cta", eventlabel: "enterprise account" })}
          >
              Get a demo
          </Button>
          <StyledDivider/>
          <BulletContainer sections={leftColBullets}/>

          <MobileViewAllButton onClick={() => expandPanel(0)}>View All Details</MobileViewAllButton>

        </AccountDetails>
      </AccountWrapper>
      <AccountWrapper>
        <AccountDetails isExpanded={panelsExpanded[1]}>
          <Headline>Self-Service Account</Headline>
          <p>Start building your own meal plan and feed your team immediately after signing up! Great for smaller groups, celebrations at work, client meetings, and more.</p>
          <Button
            to="https://www.grubhub.com/corporate/signup?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpselfservice_signup_getstarted&utm_content=getstartedCTA"
            onClick={() => handleTealiumEvent({ eventcategory: "get started page interaction", eventaction: "create your account_cta", eventlabel: "self-service account" })}
          >
              Create your account
          </Button>
          <StyledDivider/>
          <BulletContainer sections={rightColBullets}/>

          <MobileViewAllButton onClick={() => expandPanel(1)}>View All Details</MobileViewAllButton>

        </AccountDetails>
      </AccountWrapper>
    </Container>
    <Contact><span>Need help picking the best option for you? </span> <Link to="/contact-us/">Contact us</Link> and we'll be in touch. </Contact>
  </SectionWrapper>
  )
};

export default TwoCol_AccountBreakdownSection;




// --------- Helper components ---------

const BulletContainer = (props) => {
  return(
    <StyledBulletContainer>
      {/* Loop through the array from the props and pull out each section */}
      {props.sections?.map((section, i) => {
        return(
          <React.Fragment key={i}>
          <BulletHeadline key={i}>{section.headline}</BulletHeadline>
          {/* Loop through the section and display each bullet point from it */}
          {section.bullets?.map((bullet, i) => {
            return(
              <Bullet inactive={bullet.inactive} textgrey={bullet.textgrey} text={bullet.text} subtext={bullet.subtext || null} key={i}/>
            )
          })}
          </React.Fragment>
        )
      })}
    </StyledBulletContainer>
  )
}

const Bullet = (props) => {


  return(
    <StyledBullet>
      {
        props.inactive ?
          <BulletImage src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTUgMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYyICg5MTM5MCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+TWludXMgTWFyazwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJNaW51cy1NYXJrIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiPgogICAgICAgIDxsaW5lIHgxPSIyIiB5MT0iNy43MTk4MjQyMSIgeDI9IjEyLjk5MTIxMDUiIHkyPSI3LjI4MDE3NTc5IiBpZD0iUGF0aC0zIiBzdHJva2U9IiNDQkNCQ0IiIHN0cm9rZS13aWR0aD0iMi43NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy40OTU2MDUsIDcuNTAwMDAwKSByb3RhdGUoMi4yOTA2MTApIHRyYW5zbGF0ZSgtNy40OTU2MDUsIC03LjUwMDAwMCkgIj48L2xpbmU+CiAgICA8L2c+Cjwvc3ZnPg==' alt='Checkmark'/>
        :
          <BulletImage src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTUgMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JlZW4gQ2hlY2ttYXJrPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkdyZWVuLUNoZWNrbWFyayIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDMuMDAwMDAwKSIgc3Ryb2tlPSIjNzFCRDU1IiBzdHJva2Utd2lkdGg9IjIuNzcyOTIiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlN0cm9rZS00IiBwb2ludHM9IjEuMDE3NjUyMTIgNS4yNjI4NDI1NiA0LjE5Nzg4Njk5IDguOTA2NjY2NTQgMTAuMTUyOTc4NSAwLjQ2NzIxMTM2NiI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==' alt='Minus'/>
      }
      <div>
        <BulletText textgrey={props.textgrey} inactive={props.inactive}>{props.text}</BulletText>
        {props.subtext && <BulletSubtext>{props.subtext}</BulletSubtext>}
      </div>
    </StyledBullet>
  )
}
