import styled, { css } from 'styled-components';
import { queries } from "../../../../breakpoints"

export const Container = styled.div`
    display: flex;
    flex-flow:row;
    justify-content: center;
    @media (${queries.max_break_md}){
        flex-flow:column;
    }
`;

export const MobileViewAllButton = styled.button`
    position:absolute;
    width:100%;
    background-color:red;
    bottom:0; 
    left:0;
    padding: 45px 0 15px;
    border:none;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
    font-weight:bold;
    color: var(--color-primary);
    text-transform: uppercase;

    @media (${queries.min_break_md}){
        display: none;
    }
`;

export const AccountWrapper = styled.div`
    position: relative;
    @media (${queries.min_break_md}){
        &:nth-child(1){
            margin-right: 7px;
        }
        &:nth-child(2){
            margin-left: 7px;
        }
    }
`;

export const AccountDetails = styled.div`
    border: 1px solid #C7B8FF;
    text-align: center;
    max-width: 445px;
    padding: 30px 45px;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(113, 189, 85, 0.17);
    margin: 0 auto;
    overflow: hidden;
    margin-top: 50px;
    height:535px;
    position: relative;
    @media (${queries.min_break_md}){
        margin-top: -35px;
        flex: 1;
        height:unset;
    }

    ${props => props.isExpanded && css`
        flex: 1;
        height:unset;
        ${MobileViewAllButton}{
            display:none;
        }
    `}
    
`;

export const Headline = styled.h4`
    font-weight: bold;
`;

export const StyledDivider = styled.hr`
    margin: 45px 0;
`;

export const StyledBulletContainer = styled.div`
    text-align: left;
`;



export const BulletHeadline = styled.h6`
    margin: 0 0 6px;
    text-transform: uppercase;
    font-weight: bold;

    &:not(:first-child) {
        margin-top: 35px;
    }
`;

export const Contact = styled.h5`
    text-align: center;
    margin: 60px 0;
    font-weight: var(--fontWeight-light);
    line-height: 30px;
    a{
        white-space: nowrap;
    }
`;

export const StyledBullet = styled.div`
    margin: 5px 0;
    font-size: var(--fontSize-0);
    font-weight: 300;
    display: flex;
    align-items: flex-start;
`;

export const BulletImage = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 3px;
`;

export const AccountBadge = styled.img`
    width: 130px;
    height: 130px;
    margin-right: 10px;
    margin-top: 3px;
    position: absolute;
    top: -85px;
    left: -55px;
    z-index: 3;
    @media (${queries.max_break_md}){
        top: 15px;
        left: -25px;
        width: 75px;
        height: 75px;
    }
`;

export const BulletText = styled.span`
    font-family: var(--font-body);
    font-size: var(--fontSize-1);
    color: #${(props) => props.textgrey ? '9B9AA0': '212529'};
`;

export const BulletSubtext = styled.p`
    color: #9B9AA0;
    font-size: var(--fontSize-0);
    margin: 0;
`;