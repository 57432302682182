import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import React from "react"
import TwoCol_AccountBreakdownSection from "../components/page-specific/Get-Started/TwoCol_AccountBreakdownSection/TwoCol_AccountBreakdownSection"

const SsuPage = ({ location }) => {

  const faqs = [
    {
      header: "How do I create a Grubhub Corporate Account?",
      description: `<span>There's two ways to sign up for a Corporate Account. Our Enterprise Account allows you to access all ordering options, including individual meals, group ordering, catering and ongoing account management from our experienced client success team. Our Self-Service Account allows you to get started instantly and provides you with an easy way to provide individual meal perks for employees and virtual event attendees, with account support as needed. <a href="/contact-us-enterprise-account/">Contact us</a> for an Enterprise account or <a href="https://www.grubhub.com/corporate/signup?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpselfservice_signup_getstarted&utm_content=getstartedCTA">sign up now</a> for a Self-Service Account.`
    },
    {
      header: "Why should I sign up for a Corporate Account with Grubhub?",
      description: `<span>A Grubhub Corporate Account is a scaleable, cost-effective way to feed teams, clients, guests and more. <a href="https://www.grubhub.com/corporate/signup?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpselfservice_signup_getstarted&utm_content=getstartedCTA">Sign up now</a> to unlock the nations largest network of restaurant partners.</span>`
    },
    {
      header: "What should I do if I’m not sure which Corporate Account is best for me?",
      description: `<span>We’re here to help you choose which option is right for you. Simply <a href="/contact-us/">contact us</a> and we will be in touch to discuss your corporate ordering needs.</span>`
    },
    {
      header: "Where can people order Grubhub from?",
      description: `<span>People can order Grubhub from over 4,000 cities in the U.S. To find out if Grubhub delivers to you, go to <a href="https://www.grubhub.com" target="_blank" rel="noreferrer">www.grubhub.com</a> and enter your address. Learn more about Grubhub here.</span>`
    }
  ]

  return (
    <Layout
      location={location}
      title={"Get Started | Grubhub Corporate"}
      description="Corporate ordering solutions to fit your every need."
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "two ways to get started",
        pageName: "two ways to get started",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <IllustrationHeader
        headlineClassName={"extra-spacing-headling get-started-headline"}
        headline="Two ways to get started for free"
        subheadline="Corporate ordering solutions to fit your every need."
        background="radial-gradient(circle, #612889 0%, var(--color-primary-darker) 100%)"
        leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628544179/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/1-SelfSignUp/SSU-Header-ImageIllustration-Laptop.png"
        altLeftSide="Illustration of a laptop."
        rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933618/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/1-SelfSignUp/SSU-Header-ImageIllustration-Phone-Citrus.png"
        altRightSide="Illustration of a smartphone with an orange background and the Grubhub logo inside."
        mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/1-SelfSignUp/SSU-Header-ImageIllustration-Mobile-Final-Citrus.png"
        altMobile="Illustrations of a laptop and a smartphone with an orange background and the Grubhub logo inside."
      />
       <TwoCol_AccountBreakdownSection //eslint-disable-line
       />
      <FaqAccordionSection faqs={faqs} backgroundColor="#f6f6f6" />
    </Layout>
  )
}

export default SsuPage
